<template>
  <v-container>
    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Planes </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Tus datos </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div v-if="loading" class="text-center">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
            Cargando
          </div>

          <InitForm v-else :userData="userData" @next-step="nextStep(1)" />

        </v-stepper-content>

        <!-- <v-stepper-content step="2">

          <v-stepper v-model="e1_2" elevation="0">

            <v-stepper-content step="1">
              <div v-if="loading" class="text-center">
                <v-progress-circular
                  :size="100"
                  color="primary"
                  indeterminate
                ></v-progress-circular><br><br>
                Cargando
              </div>
              
              <DetailForm
                v-else-if="!loading && !loadingFinal"
                :planItemsSelected="planItemsSelected"
                :planItemsOptional="planItemsOptional"
                :stwichers="stwichers"
                :pricesOptionals="pricesOptionals"
                :pricesSelected="pricesSelected"
                @next-step="nextStep(2, 1)"
                @back="back(2, 1)"
              />
            </v-stepper-content>

            <v-stepper-content step="2">
              <div v-if="loading || loadingFinal" class="text-center">
              <v-progress-circular
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular><br><br><br>
              Estamos procesando tu cotización. . .
              </div>

              <PlanForm
                v-else-if="!loading && !loadingFinal"
                :planData="planData"
                :plans="plans"
                @next-step="nextStep"
                @back="back(2, 2)"
              />
            </v-stepper-content>

          </v-stepper>

        </v-stepper-content> -->

        <v-stepper-content step="2">

          <v-stepper v-model="e1_2" elevation="0">

            <div v-if="loading || loadingFinal" class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br><br>
              Estamos procesando tu cotización. . .
            </div>

            <PlanForm v-else-if="!loading && !loadingFinal" :planData="planData" @next-step="nextStep"
              @back="back(2, 2)" />

          </v-stepper>

        </v-stepper-content>

        <v-stepper-content step="3">
          <div v-if="loading || loadingFinal" class="text-center">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br><br>
            Estamos procesando tu cotización. . .
          </div>

          <PersonForm v-else-if="!loading && !loadingFinal" :userData="userData" @next-step="nextStep(3)"
            @back="back(3)" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import InitForm from "@/components/InitForm.vue";
// import DetailForm from "@/components/DetailForm.vue";
import PlanForm from "@/components/PlanForm.vue";
import PersonForm from "@/components/PersonForm.vue";
import ShieldService from "../services/shield.service";

export default {
  name: "Form",
  props: ["landing"],
  components: {
    InitForm,
    // DetailForm,
    PlanForm,
    PersonForm,
  },
  data() {
    return {
      e1: 1,
      e1_2: 1,
      loading: false,
      loadingFinal: false,
      plans: [],
      userData: {},
      planData: {},
      planItemsSelected: ["MACC", "ITP", "IC"],
      planItemsOptional: [],
      stwichers: {
        item0: false,
        item1: false,
        item2: false,
        item3: false,
        item4: false,
      },
      pricesSelected: [[100, "MACC"], [50, "ITP"], [30, "IC"]],
      pricesOptionals: [[100, "ITP Temp Acc"], [50, "Desm Acc"], [30, "GMxACC"], [25, "RGFuneACC"], [40, "RC"]]
    };
  },
  mounted() {
    // const query = this.$route.query;
    // if (query) {
    //   if (query.preview === "true") {
    //     this.userData.name = localStorage.getItem("name"),
    //       this.userData.email = localStorage.getItem("email"),
    //       this.userData.phoneNumber = localStorage.getItem("phoneNumber")
    //     this.e1 = 2
    //   }
    // }
  },
  methods: {
    // async nextStep(step, substep, plan ) {
    //   this.loading = true;
    //   if (step === 1) {
    //     this.e1 = 2;
    //   } else if (step === 2) {
    //     if(substep == 1){
    //       try{
    //         // console.log("options form: ", this.planItemsOptional)
    //         this.loadingPlans = true;
    //         this.plans = await ShieldService.getPlans(this.planItemsOptional);
    //         this.loadingPlans = false;

    //         // console.log("Plans: ", this.plans)

    //       }catch(error){
    //         console.log("error: ", error)
    //       }

    //       this.e1_2 = 2
    //     }else if(substep == 2){
    //       this.planData = plan;
    //       this.e1 = 3;        
    //     }

    //   } else if (step === 3) {
    //     // Conectar con SHIELD
    //     this.loadingFinal = true;

    //     try {
    //       const res = await ShieldService.createInsurance(
    //         this.userData,
    //         this.planData
    //       );
    //       // Redirigir a Flow
    //       window.location.href = res;
    //     } catch (error) {
    //       this.loadingFinal = false;
    //       this.loading = false;
    //       this.$alert("Ocurrió un error. Verifica que tus datos sean correctos","Error","error");
    //     }
    //   }
    //   this.loading = false;
    // },
    async nextStep(step, plan) {
      this.loading = true;
      if (step === 1) {
        if (this.landing == true) {
          localStorage.setItem("name", this.userData.name);
          localStorage.setItem("email", this.userData.email);
          localStorage.setItem("phoneNumber", this.userData.phoneNumber);
          window.location.href = "/cotizador?preview=true"
        } else {
          this.e1 = 2;
        }
      } else if (step === 2) {
        this.planData = plan;
        this.e1 = 3;
      } else if (step === 3) {
        // Conectar con SHIELD
        this.loadingFinal = true;

        try {
          const res = await ShieldService.createInsurance(
            this.userData,
            this.planData
          );
          // Redirigir a Flow
          window.location.href = res;
        } catch (error) {
          this.loadingFinal = false;
          this.loading = false;
          this.$alert("Ocurrió un error. Verifica que tus datos sean correctos", "Error", "error");
        }
      }
      this.loading = false;
    },
    // back(step, substep) {
    //   if (step === 2) {
    //     if(substep == 1){
    //       this.e1 = 1;
    //     }else if(substep == 2){
    //       this.e1_2 = 1
    //     }
    //   } else if (step === 3) {
    //     this.e1 = 2;
    //   }
    // },
    back(step) {
      if (step === 2) {
        this.e1 = 1;
      } else if (step === 3) {
        this.e1 = 2;
      }
    },
  },
};
</script>